.blog-card{
    background-color: #000000;
    padding: 5vh 3vw;
    border-radius: 15px;
    margin: 2vh 0;
}

.blog-date, .blog-title, .blog-description{
    background-color: #000000;
}
.blog-description{
    font-size: large;
    color: #E5E5E5;
}
.blog-title{
    color: #FFFFFF;
}
.blogBox{
    background-color: #F7F7F7;
    width : 45vw;
}

@media screen and (max-width: 1225px) {
    .blogBox{
        width : 95%;
    }
    .blogTitle{
        padding: 2vh 1vw;
    }
}