.workBox{
    background-color: #F7F7F7;
    width : 45vw;
}

.workTitle{
    position: sticky;
}

@media screen and (max-width: 1225px) {
    .workBox{
        width : 96%;
    }
    .workTitle{
        padding: 2vh 1vw;
    }
}
