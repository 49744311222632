.navbar{
    display: flex;
    justify-content: space-between;
    background-color: #F7F7F7;
    height : 8vh;
}

.navbarLogo{
    text-decoration:none; 
    color:black;
}