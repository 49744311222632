.respNavbar{
    width: 92vw;
    display: none; 
    justify-content: space-between; 
    background-color: black;
    height : 8vh;
    border-radius: 15px;
    padding: 0 2vw;
}

.routesDescription{
    display: none;
}

.link{
    display: flex;
    background-color: black;
    border-radius: 15px;
    color: #F7F7F7;
    text-decoration: none;
    font-size: 25px;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1225px){
    .respNavbar{
        display: flex; 
        
    }
    .routesDescription{
        display: grid;
    }
}